import { createAsync, query, redirect } from "@solidjs/router";
import { type JSX } from "solid-js";
import { DashNav } from "../components/dash/main-navigation";

interface Props {
  children: JSX.Element;
}

const protect = query(async () => {
  "use server";

  if (import.meta.env.PROD) {
    throw redirect("/");
  }

  return { user: { id: "123" } };
}, "route-protection");

export const route = {
  preload: async () => {
    return protect();
  },
};

export default function DashLayout(props: Props) {
  createAsync(async () => protect());

  return (
    <div class="grid grid-cols-[auto,1fr] min-h-screen h-full">
      <DashNav />
      {props.children}
    </div>
  );
}
