import { createAsync, type RouteDefinition } from "@solidjs/router";
import { Suspense } from "solid-js";
import { MetricCard } from "~/components/custom/metric-component";
import { getStatsFromUser } from "~/lib/db/stats";
import Mail from "lucide-solid/icons/mail";

export const route: RouteDefinition = {
  preload: async () => {
    "use server";

    /**
     * @debt hardcoded user id
     */
    return getStatsFromUser("rc-123");
  },
};
export default function Metrics() {
  /**
   * @debt hardcoded user id
   */
  const stats = createAsync(() => getStatsFromUser("rc-123"));

  return (
    <>
      <h1>Metrics</h1>
      <MetricCard
        title="Emails Sent"
        value="123"
        trend={12}
        icon={Mail}
        color="bg-blue-100"
      />
      <Suspense fallback="loading">
        <pre>{JSON.stringify(stats(), null, 2)}</pre>
      </Suspense>
      <button
        type="button"
        onClick={() => {
          throw new Error("Sentry Frontend Error");
        }}
      >
        Throw error
      </button>
    </>
  );
}
