import { createAsync, type RouteDefinition } from "@solidjs/router";
import { getStatsFromUser } from "~/lib/db/stats";
// import { EmailChart } from "~/components/custom/email-chart";
import { EmailChart } from "~/components/custom/mail-chart";
import { MetricCards } from "~/components/dash/metric-cards";
import { SectionHeader } from "~/components/dash/section-header";
import { StatsCard } from "~/components/dash/stats-card";

export const route: RouteDefinition = {
  preload: async () => {
    "use server";

    /**
     * @debt hardcoded user id
     */
    return {}; //getStatsFromUser("rc-123");
  },
};
export default function Metrics() {
  /**
   * @debt hardcoded user id
   */
  const stats = createAsync(() => getStatsFromUser("rc-123"));

  return (
    <div class="p-8">
      <div class="flex justify-between items-center mb-8">
        <SectionHeader
          title="Analytics"
          subtitle="How are your waitlist performing."
        />

        <div class="flex gap-4 items-center">
          <select class="bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 rounded-lg px-4 py-2 text-sm text-gray-900 dark:text-gray-300">
            <option>Last 7 days</option>
            <option>Last 30 days</option>
            <option>Last 90 days</option>
          </select>
          <button class="bg-indigo-600 text-white px-4 py-2 rounded-lg text-sm font-medium hover:bg-indigo-700 transition-colors">
            Export Report
          </button>
        </div>
      </div>

      <MetricCards />
      <EmailChart />

      <section class="grid grid-cols-2 gap-6 mt-8">
        <StatsCard
          title="Top Performing Emails"
          statList={[
            { key: "Welcome Series: Day 1", value: "68%" },
            { key: "Monthly Newsletter", value: "62%" },
            { key: "Product Update", value: "57%" },
          ]}
        />
        <StatsCard
          title="Engagement by Time"
          statList={[
            { key: "9:00 AM - 12:00 PM", value: "32%" },
            { key: "12:00 PM - 3:00 PM", value: "28%" },
            { key: "3:00 PM - 6:00 PM", value: "24%" },
          ]}
        />
      </section>
    </div>
  );
}
